<template>
	<div class="login-content">
		<p class="welcome-title">欢迎登录,理光技术中心!</p>
		<div class="login-box">
			<div class="login-box-left">
				<img class="login-logo" src="@/assets/images/logo2.png" alt="">
				<ul class="login-ul">
					<li>
						<!-- <span class="label"><span>*</span></span> -->
						<input type="text" v-model="code" placeholder="请输入用户ID/手机/邮箱号">
					</li>
					<p style="font-size:15px;">请输入您注册时填写的用户ID/手机/邮箱号，系统将发送邮件到您的邮箱帮您找回密码。</p>
					<button class="submit-btn" @click="handleSubmit">确认找回</button>

				</ul>
			</div>
			<img class="login-box-right" src="@/assets/images/beijing.png" alt="">
		</div>
		<p class="banquan">版权所有 © 2021理光（中国）投资有限公司</p>
		<p class="beian">备案编号：沪ICP备08111514号</p>
	</div>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			code: '',
		}
	},
	methods: {
		handleSubmit:debounce(function() {
			if(this.code == '') {
				this.$message.error('请输入用户ID/手机/邮箱号');
				return
			}
			let that = this;
			that.$request.post(
				"forgetPass",
				false,
				{
					code: that.code,
				},
				function (r) {
					if (r.code == "0") {
						that.$message.success('邮件已发送，请尽快登录邮箱查看');
						that.$router.push({
							name: 'login'
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},1000),
	}
}
</script>
<style lang="scss" scoped>
.lh-17{
	line-height: 17px;
}
.login-content{
	position: absolute;
	width: 100%;
	height: 100%;
	background: url('~@/assets/images/beijing2.png') no-repeat;
	background-color: #ECF7FF;
	background-size: contain;
	background-position: center;
	.welcome-title{
		position: absolute;
		right: 5%;
		top: 5%;
		font-size: 22px;
		font-weight: 500;
		color: #117FFC;
	}
	.login-box{
		display: flex;
		position: absolute;
		top: 14%;
		left: 18%;
		width: 62%;
		height: 70%;
		border-radius: 20px;
		background-color: #fff;
		.login-box-left{
			flex: 3;
			.login-logo{
				display: block;
				width: 35%;
				margin: 7% auto;
			}
			.login-ul{
				width: 70%;
				margin: 0 auto;
				li{
					position: relative;
					list-style: none;
					margin-bottom: 20px;
					display: flex;
					.label{
						// width: 100px;
						line-height: 36px;
						span{
							color: red;
						}
					}
					input,
					select{
						flex: 1;
						height: 38px;
						box-sizing: border-box;
						padding-left: 10px;
						font-size: 14px;
						outline: none;
						border: 1px solid #333;
						border-radius: 5px;
					}
				}
			}
		}
	}
}
.submit-btn{
	display: block;
	outline: none;
	border: none;
	width: 100%;
	height: 36px;
	background: #117FFC;
	box-shadow: 0px 5px 15px 0px rgba(17 ,127 ,252 , 50%);
	border-radius: 8px;
	color: #fff;
	text-align: center;
	line-height: 36px;
	font-weight: 500;
	cursor: pointer;
	margin-top: 60px;
}
.banquan{
	text-align: center;
	color: #A8A8A8;
	position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -159px;
}
.beian{
	text-align: center;
	color: #0067FF;
	position: absolute;
    bottom: 16px;
    left: 50%;
    margin-left: -114px;
}
</style>
